import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Main } from "./Routes";
import Home from "../pages/Home";
import NotFound from "../pages/NotFound";
import FAQ from "../pages/FAQ";
import Contact from "../pages/Contact";
import AboutUs from "../pages/AboutUs";
import BullMaster from "../pages/BullMaster";
import AmericanBully from "../pages/AmericanBully";
import Puppies from "../pages/Filhotes";

export default function AppRouter() {
  return (
    <Main>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/american-bully" element={<AmericanBully />} />
          <Route path="/bull-master" element={<BullMaster />} />
          <Route path="/filhotes" element={<Puppies />} />
          {/* <Route path="/duvidas" element={<FAQ />} /> */}
          <Route path="/sobre" element={<AboutUs />} />
          <Route path="/contato" element={<Contact />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </Router>
    </Main>
  );
}
