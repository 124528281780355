import { FaFacebook, FaInstagram, FaWhatsapp } from "react-icons/fa";
import {
  StyledDevelopedBy,
  StyledDiv,
  StyledFooter,
  StyledIconItem,
  StyledIconList,
  StyledRightsReserved,
} from "./Footer";

function Footer() {
  return (
    <StyledFooter>
      <StyledIconList>
        <StyledIconItem>
          <a
            href="https://www.instagram.com/avr_bulls/"
            target="_blank"
            rel="noreferrer"
            alt="Instagram Avr Bulls"
            aria-label="Instagram Avr Bulls"
          >
            <FaInstagram />
          </a>
        </StyledIconItem>
        <StyledIconItem>
          <a
            href="https://www.facebook.com/profile.php?id=100063102136124&mibextid=9R9pXO"
            target="_blank"
            rel="noreferrer"
            alt="Facebook Avr Bulls"
            aria-label="Facebook Avr Bulls"
          >
            <FaFacebook />
          </a>
        </StyledIconItem>

        <StyledIconItem>
          <a
            href="https://api.whatsapp.com/send?phone=555195255373&text=Ol%C3%A1,%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%E2%80%A6"
            target="_blank"
            rel="noreferrer"
            alt="WhatsApp  Avr Bulls"
            aria-label="WhatsApp  Avr Bulls"
          >
            <FaWhatsapp />
          </a>
        </StyledIconItem>
      </StyledIconList>
      <StyledDiv>
        <StyledRightsReserved>
          Horário de Atendimento: <br></br>Segunda-feira a Sábado, das 8h às 20h
        </StyledRightsReserved>
        <StyledRightsReserved>
          © 2023 Direitos Reservados |{" "}
          <a
            href="https://www.avrbulls.com.br/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Ir para o Avr Bulls"
          >
            <span>Avr Bulls</span>
          </a>
        </StyledRightsReserved>
      </StyledDiv>
      <StyledDevelopedBy>
        Desenvolvido por{" "}
        <a
          href="https://www.edtech1985.com.br/projetos"
          target="_blank"
          rel="noreferrer"
          alt="Desenvolvedor Web edtech1985"
          aria-label="Criação de Websites edtech1985"
        >
          <span>
            <strong>edtech1985</strong>
          </span>
        </a>
      </StyledDevelopedBy>
    </StyledFooter>
  );
}

export default Footer;
