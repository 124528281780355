import React from "react";
import {
  WelcomeParagraph,
  CallToAction,
  CallToActionText,
  WelcomeH2,
  CallToActionLink,
  StyledALink,
  WhatsAppIcon,
  DivOpacity,
} from "./Home";
import { HomePage } from "./Home";

const Home = () => {
  return (
    <HomePage>
      <DivOpacity>
        <WelcomeParagraph>
          Bem-vindos á família Avr Bulls! <br></br>
        </WelcomeParagraph>
        <WelcomeH2>
          Aqui o bem-estar dos nossos cães são nossa prioridade.
        </WelcomeH2>
        <CallToAction>
          <CallToActionText>
            Temos uma enorme responsabilidade por cada vida gerada, por isso nos
            esforçamos para garantir que cada cão de nossa criação viva uma vida
            plena e feliz, desde os primeiros momentos conosco até a sua
            transição para uma família amorosa. Valorizamos a conexão entre os
            cães e seus donos, e nosso objetivo é facilitar a formação de laços
            profundos e duradouros.
          </CallToActionText>
          <StyledALink
            href="https://api.whatsapp.com/send?phone=555192002595&text=Ol%C3%A1,%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%E2%80%A6"
            target="_blank"
            rel="noreferrer"
            alt="WhatsApp  Avr Bulls"
            aria-label="WhatsApp Avr Bulls"
          >
            <CallToActionLink>
              <WhatsAppIcon />
              &nbsp; Entre em contato via WhatsApp &nbsp;
              <WhatsAppIcon />
            </CallToActionLink>
          </StyledALink>
        </CallToAction>
      </DivOpacity>
    </HomePage>
  );
};

export default Home;
