import React, { useState, useEffect } from "react";
import {
  CarouselImage,
  CarrosselContainer,
  DownloadLink,
  Indicator,
  IndicatorContainer,
  LeftArrowButton,
  Navigation,
  RightArrowButton,
} from "./BullMaster";
import BullMasterSection from "./BullMasterSection";
import {
  BullMasterBackground,
  BullMasterSectionContainer,
} from "./BullMasterSectionStyles";

const carouselImages = [
  require("../../components/assets/pdf/BULLMASTER-01.jpg"),
  require("../../components/assets/pdf/BULLMASTER-02.jpg"),
  require("../../components/assets/pdf/BULLMASTER-03.jpg"),
  require("../../components/assets/pdf/BULLMASTER-04.jpg"),
  require("../../components/assets/pdf/BULLMASTER-05.jpg"),
  require("../../components/assets/pdf/BULLMASTER-06.jpg"),
  require("../../components/assets/pdf/BULLMASTER-07.jpg"),
  require("../../components/assets/pdf/BULLMASTER-08.jpg"),
];
const BullMaster = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselImages.length);
  };

  const prevImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? carouselImages.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    const interval = setInterval(nextImage, 5000); // intervalo de troca de slide

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <BullMasterSectionContainer>
        <BullMasterBackground />
        <BullMasterSection />
      </BullMasterSectionContainer>
      <CarrosselContainer>
        <CarouselImage
          src={carouselImages[currentIndex]}
          alt={`Imagem ${currentIndex + 1}`}
        />

        <Navigation>
          <LeftArrowButton onClick={prevImage}>←</LeftArrowButton>
          <RightArrowButton onClick={nextImage}>→</RightArrowButton>
        </Navigation>
        <IndicatorContainer>
          {carouselImages.map((_, index) => (
            <Indicator key={index} isActive={index === currentIndex} />
          ))}
        </IndicatorContainer>

        <DownloadLink
          id="download-link"
          href="https://drive.google.com/u/0/uc?id=1bOTnnISKQGuea9DWnusTM6a-Nme84_cW&export=download"
          download
        >
          Baixar PDF
        </DownloadLink>
      </CarrosselContainer>
      <CarrosselContainer>
        <CarouselImage
          src={carouselImages[currentIndex]}
          alt={`Imagem ${currentIndex + 1}`}
        />

        <Navigation>
          <LeftArrowButton onClick={prevImage}>←</LeftArrowButton>
          <RightArrowButton onClick={nextImage}>→</RightArrowButton>
        </Navigation>
        <IndicatorContainer>
          {carouselImages.map((_, index) => (
            <Indicator key={index} isActive={index === currentIndex} />
          ))}
        </IndicatorContainer>

        <DownloadLink
          id="download-link"
          href="https://drive.google.com/u/0/uc?id=1bOTnnISKQGuea9DWnusTM6a-Nme84_cW&export=download"
          download
        >
          Baixar PDF
        </DownloadLink>
      </CarrosselContainer>
    </>
  );
};

export default BullMaster;
