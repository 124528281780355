// BullMasterSectionStyles.js
import styled from "styled-components";

export const BullMasterSectionContainer = styled.section`
  text-align: center;
  padding: 2rem;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

export const BullMasterTitle = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  color: var(--light-red);

  @media (max-width: 768px) {
    font-size: 1.75rem;
  }
`;

export const BullMasterSubtitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: var(--dark-red);

  @media (max-width: 768px) {
    font-size: 1.25rem;
  }
`;

export const BullMasterParagraph = styled.p`
  font-size: 1.15rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  color: #f2f2f2;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;
