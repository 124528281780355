import React from "react";
import { Card, Container, InstagramLink } from "./Feed";
import post1 from "../../../components/assets/feed/post1.jpg";
import post2 from "../../../components/assets/feed/post2.jpg";
import post3 from "../../../components/assets/feed/post3.jpg";
import post4 from "../../../components/assets/feed/post4.jpg";

const InstagramCards = () => {
  const instagramPosts = [
    {
      id: 1,
      link: "https://www.instagram.com/p/Ctc-1uOOt58/",
      image: post1,
    },
    {
      id: 2,
      link: "https://www.instagram.com/p/CtXukiPO6lL/",
      image: post2,
    },
    {
      id: 3,
      link: "https://www.instagram.com/p/CwZAF6gqIJ3/",
      image: post3,
    },
    {
      id: 4,
      link: "https://www.instagram.com/p/CuHtUyBulwt/",
      image: post4,
    },
  ];

  return (
    <Container>
      {instagramPosts.map((post) => (
        <InstagramLink
          key={post.id}
          href={post.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Card>
            <img src={post.image} alt={`Post ${post.id}`} />
          </Card>
        </InstagramLink>
      ))}
    </Container>
  );
};

export default InstagramCards;
