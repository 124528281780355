import React from "react";
import {
  AboutUsContainer,
  WelcomeParagraph,
  CallToAction,
  CallToActionText,
  WelcomeH2,
  CallToActionLink,
  StyledALink,
  IGIcon,
  DivText,
  DivImg,
  AboutUsImg,
} from "./AboutUs";

import AboutImagem from "../../components/assets/aboutus2.png";

const AboutUs = () => {
  return (
    <AboutUsContainer>
      <DivText>
        <WelcomeParagraph>Sobre nós</WelcomeParagraph>
        <WelcomeH2>Paixão pela criação responsável desde 2013.</WelcomeH2>
        <CallToAction>
          <CallToActionText>
            Priorizamos o bem-estar dos animais e buscamos garantir a saúde, o
            cuidado adequado, a socialização e o desenvolvimento saudável dos
            filhotes. Selecionamos cuidadosamente os cães reprodutores com base
            em critérios de saúde e temperamento. Realizamos exames de saúde
            como testes genéticos e radiografias para identificar possíveis
            problemas hereditários e garantir que os filhotes tenham menos
            probabilidade de desenvolver doenças genéticas. Criamos com amor,
            somos verdadeiros admiradores dessa raça incrível!
          </CallToActionText>
          <StyledALink
            href="https://www.instagram.com/avr_bulls/"
            target="_blank"
            rel="noreferrer"
            alt="Instagram Avr Bulls"
            aria-label="Instagram Avr Bulls"
          >
            <CallToActionLink>
              <IGIcon />
              &nbsp; Nos acompanhe no Instagram &nbsp;
              <IGIcon />
            </CallToActionLink>
          </StyledALink>
        </CallToAction>
      </DivText>
      <DivImg>
        <AboutUsImg src={AboutImagem} alt="sobre nós" />
      </DivImg>
    </AboutUsContainer>
  );
};

export default AboutUs;
