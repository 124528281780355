import React, { useState, useEffect } from "react";
import americanBullyImage1 from "../../components/assets/Holandes-cut-removebg-preview.png";
import americanBullyPuppy from "../../components/assets/puppy.png";
import {
  GridContainer,
  GridDescription,
  GridImage,
  GridItem,
  GridSection,
  GridTitle,
  ImageContainer,
  ImageContainerMobile,
  ImageContainerMobile2,
  MainImage,
  PageContainer,
  SecondaryImage,
  Section,
  Subtitle2,
  Text,
  TextContainer,
  Title,
} from "./AmericanBully";
import {
  CloseButton,
  ModalContent,
  ModalOverlay,
  ModalSubTitle,
  ModalText,
  ModalTitle,
  ProductImage,
} from "../../components/Modal/Modal";
import { WhatsAppButton } from "../../components/WhatsAppButton";

import AmericanBullyData from "../../db/AmericanBully.json";

import { getImagePath } from "./imagePathUtil";

import AOS from "aos";
import "aos/dist/aos.css";

const AmericanBully = () => {
  const [selectedBully, setSelectedBully] = useState(null);

  const openModal = (bully) => {
    setSelectedBully(bully);
  };

  const closeModal = () => {
    setSelectedBully(null);
  };

  const handleOutsideClick = (event) => {
    if (event.target === event.currentTarget) {
      closeModal();
    }
  };

  useEffect(() => {
    AOS.init({ duration: 3000 });
  }, []);

  return (
    <PageContainer>
      <div className="animation" data-aos="fade-right">
        <Section>
          <TextContainer>
            <Title>American Bully</Title>
            <Text>
              O American Bully é conhecido por seu porte musculoso, estrutura
              robusta e cabeça larga. Eles possuem uma aparência intimidadora,
              mas têm um temperamento amigável e sociável. Essa raça é conhecida
              por ser leal, afetuosa e amigável com pessoas. Eles são conhecidos
              por sua tolerância e paciência com crianças, o que os torna uma
              escolha popular como cães de família.
            </Text>
          </TextContainer>
          <ImageContainer>
            <MainImage
              src={americanBullyImage1}
              alt="American Bully Holandês"
            />
          </ImageContainer>
        </Section>
      </div>

      <div className="animation" data-aos="fade-left" data-aos-duration="4000">
        <Section>
          <ImageContainerMobile>
            <SecondaryImage src={americanBullyPuppy} alt="American Bully" />
          </ImageContainerMobile>
          <TextContainer>
            <Subtitle2>Excelência na Criação Responsável</Subtitle2>
            <Text>
              Nossa abordagem à criação responsável coloca o bem-estar dos cães
              em primeiro plano. Dedicamo-nos a garantir a saúde, o cuidado
              adequado, a socialização e o desenvolvimento saudável dos
              filhotes. A seleção de cães reprodutores é meticulosa, priorizando
              critérios de saúde e temperamento. Exames genéticos e radiografias
              ajudam a identificar possíveis problemas hereditários, assegurando
              que os filhotes tenham menos predisposição a doenças genéticas.
            </Text>
          </TextContainer>
          <ImageContainerMobile2>
            <SecondaryImage src={americanBullyPuppy} alt="American Bully" />
          </ImageContainerMobile2>
        </Section>
      </div>

      <GridSection>
        <Subtitle2>Padrões da Raça</Subtitle2>
        <GridTitle>
          Trabalhamos com alguns padrões da raça: American Bully Micro e
          American Bully Pocket
        </GridTitle>
        <div className="animation" data-aos="fade-up">
          <GridContainer>
            {AmericanBullyData.bullyPatterns.map((bully) => (
              <GridItem key={bully.nome} onClick={() => openModal(bully)}>
                <GridImage src={getImagePath(bully.img_url)} alt={bully.nome} />
                <GridDescription>
                  <GridTitle>{bully.nome}</GridTitle>
                  <Text>{bully.tamanho2}</Text>
                </GridDescription>
              </GridItem>
            ))}
          </GridContainer>
        </div>

        {selectedBully && (
          <ModalOverlay onClick={handleOutsideClick}>
            <ModalContent>
              <CloseButton onClick={closeModal}>Fechar</CloseButton>
              <ModalTitle>{selectedBully.nome}</ModalTitle>
              <ModalSubTitle>{selectedBully.descricao}</ModalSubTitle>
              <ProductImage
                src={getImagePath(selectedBully.img_url)}
                alt={selectedBully.nome}
                aria-label={selectedBully.nome}
              />

              <ModalText>{selectedBully.tamanho}</ModalText>
              <ModalText>{selectedBully.caracteristicas}</ModalText>
              <WhatsAppButton>
                <a
                  href={`https://api.whatsapp.com/send?phone=555195255373&text=Ol%C3%A1%20Avr%20Bulls,%20estou%20interessado%20no%20${encodeURIComponent(
                    selectedBully.nome
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Avr Bulls Whatsapp"
                >
                  Contato via WhatsApp
                </a>
              </WhatsAppButton>
            </ModalContent>
          </ModalOverlay>
        )}
      </GridSection>

      {""}
      {""}
    </PageContainer>
  );
};

export default AmericanBully;
