import styled from "styled-components";
import backgroundImg from "../../components/assets/background.png";

export const Container = styled.div`
  text-align: center;
  background-image: url(${backgroundImg});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center right;
  height: 100vh;

  @media only screen and (max-width: 600px) {
  }
`;

export const DivOpacity = styled.div`
  width: 40vw;
  align-items: center;
  justify-content: center;
  padding: 4rem;
  margin: 5rem auto;
  background-color: rgba(0, 0, 0, 0.75);
`;

export const Titulo = styled.h1`
  font-size: 2.5rem;
  color: var(--light-red);
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

export const IconesContato = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

export const ItemContato = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem;
  width: 16rem;
  transition: transform 0.2s;

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: var(--light-red);
    font-size: 1.5rem;
    transition: color 0.2s;

    &:hover {
      color: #f2f2f2;
    }
  }

  span {
    margin-top: 0.5rem;
  }
`;
