import React from "react";
import {
  BannerImg,
  DivOpacity,
  PuppiesContainer,
  Text,
  Title,
} from "./Puppies";

import BannerCruza from "../../components/assets/cruza.png";

const Puppies = () => {
  return (
    <PuppiesContainer>
      <DivOpacity>
        <Title>Filhotes Disponíveis</Title>
        <Text>Confira abaixo nossos cruzamentos recentes</Text>
      </DivOpacity>

        <BannerImg src={BannerCruza} alt="Filhote de American Bully" />

      
    </PuppiesContainer>
  );
};
export default Puppies;
