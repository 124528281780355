import styled from "styled-components";
import backgroundImage from "../../components/assets/dogss.png";

export const BullMasterBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${backgroundImage});
  opacity: 0.6; /* Ajuste a opacidade conforme necessário */
  z-index: -1;
`;

export const BullMasterSectionContainer = styled.section`
  text-align: center;
  padding: 2rem;
  justify-content: center;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

export const BullMasterTitle = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  color: var(--light-red);
  font-family: "Archivo Black", sans-serif;

  @media (max-width: 768px) {
    font-size: 1.75rem;
  }
`;

export const BullMasterSubtitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: var(--dark-red);
  font-family: "Archivo Black", sans-serif;

  @media (max-width: 768px) {
    font-size: 1.25rem;
  }
`;

export const BullMasterParagraph = styled.p`
  font-size: 1.15rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  color: #f2f2f2;
  font-family: "Lato", sans-serif;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const CenterDiv = styled.div`
  display: flex;
  justify-content: center;
`;

export const CTAButton = styled.a`
  display: block;
  text-align: center;
  background: var(--dark-red);
  color: white;
  padding: 1rem 2rem;
  border-radius: 15px;
  margin-top: 1rem;
  text-decoration: none;
  transition: background-color 1s ease-in-out, 1s ease-out;
  border: 2px solid transparent;
  width: 50%;

  &:hover {
    color: var(--light-red);
    background-color: var(--dark);
    border: 2px solid var(--light-red);

    box-shadow: 0px 0px 6px 6px var(--light-red);
  }
`;
