import React, { useEffect } from "react";
import {
  WelcomeParagraph,
  CallToAction,
  CallToActionText,
  WelcomeH2,
  CallToActionLink,
  StyledALink,
  WhatsAppIcon,
  DivOpacity,
  BullCard,
  Icon,
  BullContent,
  BullsContainer,
  BottomDiv,
  InstaFeed,
  DogIcon,
  ButtonsDiv,
  CallToActionBullMasterLink,
  BullLink,
} from "./Home";
import { HomePage } from "./Home";

import AOS from "aos";
import "aos/dist/aos.css";
import InstagramCards from "./Feed";

const Home = () => {
  useEffect(() => {
    AOS.init({ duration: 3000 });
  }, []);

  return (
    <HomePage>
      <DivOpacity>
        <WelcomeParagraph>
          Bem-vindos á família Avr Bulls! <br></br>
        </WelcomeParagraph>
        <WelcomeH2>
          Aqui o bem-estar dos nossos cães são nossa prioridade.
        </WelcomeH2>
        <CallToAction>
          <CallToActionText>
            Temos uma enorme responsabilidade por cada vida gerada, por isso nos
            esforçamos para garantir que cada cão de nossa criação viva uma vida
            plena e feliz, desde os primeiros momentos conosco até a sua
            transição para uma família amorosa. Valorizamos a conexão entre os
            cães e seus donos, e nosso objetivo é facilitar a formação de laços
            profundos e duradouros.
          </CallToActionText>

          <ButtonsDiv>

          <StyledALink
            href="/bull-master"
            target="_blank"
            rel="noreferrer"
            alt="BullMaster - Avr Bulls"
            aria-label="BullMaster - Avr Bulls"
          >
            <CallToActionBullMasterLink>
              <DogIcon />
              &nbsp; Conheça a BullMaster &nbsp;
              <DogIcon />
            </CallToActionBullMasterLink>
          </StyledALink>

          <StyledALink
            href="https://api.whatsapp.com/send?phone=555195255373&text=Ol%C3%A1,%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%E2%80%A6"
            target="_blank"
            rel="noreferrer"
            alt="WhatsApp  Avr Bulls"
            aria-label="WhatsApp Avr Bulls"
          >
            <CallToActionLink>
              <WhatsAppIcon />
              &nbsp; Entre em contato via WhatsApp &nbsp;
              <WhatsAppIcon />
            </CallToActionLink>
          </StyledALink>

          </ButtonsDiv>

        </CallToAction>



        <BullsContainer>
          <div className="animation" data-aos="fade-right">
            <BullCard>
              <Icon className="fa fa-trophy" aria-hidden="true" />{" "}
              <BullContent>
                <h3>Linhagem de Campeões</h3>
                <p>
                  Nossos cães participam de diversos eventos e sua excelência
                  genética sempre garante muitos prêmios.
                </p>
              </BullContent>
            </BullCard>
          </div>
          <div className="animation" data-aos="fade-up">
            <BullLink href="/bull-master">

            <BullCard>
              <Icon className="fa fa-globe" aria-hidden="true" />{" "}
              <BullContent>
                <h3>Pedigree Internacional</h3>
                <p>
                  Somos cadastrados na entidade IDR e entregamos o pedigree na
                  entrega do filhote.
                </p>
              </BullContent>
            </BullCard>
            </BullLink>
          </div>

          <div className="animation" data-aos="fade-left">
            <BullCard>
              <Icon className="fa fa-heartbeat" aria-hidden="true" />{" "}
              <BullContent>
                <h3>Suporte Vitalício</h3>
                <CallToActionText>
                  Oferecemos suporte contínuo e orientação pra toda a vida do
                  seu companheiro. Estaremos sempre disponíveis para responder
                  suas dúvidas, oferecer conselhos e fornecer orientações sobre
                  cuidados, treinamento e bem-estar.
                </CallToActionText>
              </BullContent>
            </BullCard>
          </div>
        </BullsContainer>

        <BottomDiv>
          <div id="text">
            <CallToActionText>
              Nossa prioridade é o sucesso e felicidade do seu cão, e estaremos
              ao seu lado em casa etapa dessa jornada.
            </CallToActionText>
          </div>

          <InstaFeed>
            <InstagramCards />
          </InstaFeed>
        </BottomDiv>
      </DivOpacity>
    </HomePage>
  );
};

export default Home;
