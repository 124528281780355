// BullMasterSection.js
import React from "react";
import {
  BullMasterSectionContainer,
  BullMasterTitle,
  BullMasterSubtitle,
  BullMasterParagraph,
  CTAButton,
  CenterDiv,
} from "./BullMasterSectionStyles";

const BullMasterSection = () => {
  return (
    <BullMasterSectionContainer>
      <BullMasterTitle>BullMaster</BullMasterTitle>
      <BullMasterSubtitle>
        Distribuidor exclusivo Bullmaster super premium no RS
      </BullMasterSubtitle>
      <BullMasterParagraph>
        Sabemos que o fator genético é o principal responsável pelo padrão
        estético do cão. Porém, escolher o alimento certo é crucial para
        potencializar o seu desenvolvimento e garantir a sua saúde. BULLMASTER®
        é um alimento SUPER PREMIUM para cães a base de proteína e com alto teor
        calórico, especialmente formulado para atender às necessidades
        nutricionais das raças bull.
      </BullMasterParagraph>
      <CenterDiv>
        <CTAButton
          href="https://view.forms.app/bullmasterrs/untitled-form"
          target="_blank"
        >
          Seja um revendedor
        </CTAButton>
      </CenterDiv>
    </BullMasterSectionContainer>
  );
};

export default BullMasterSection;
