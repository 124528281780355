import React, { useState } from "react";
import americanBullyImage1 from "../../components/assets/Holandes1.png";
import americanBullyHollandImage from "../../components/assets/gta-holandes.jpg";
import {
  GridContainer,
  GridDescription,
  GridImage,
  GridItem,
  GridSection,
  GridTitle,
  ImageContainer,
  ImageContainerMobile,
  ImageContainerMobile2,
  MainImage,
  PageContainer,
  SecondaryImage,
  Section,
  Subtitle,
  Subtitle2,
  Text,
  TextContainer,
  Title,
} from "./AmericanBully";
import {
  CloseButton,
  ModalContent,
  ModalOverlay,
  ModalSubTitle,
  ModalText,
  ModalTitle,
  ProductImage,
} from "../../components/Modal/Modal";
import { WhatsAppButton } from "../../components/WhatsAppButton";

import AmericanBullyData from "../../db/AmericanBully.json";

import { getImagePath } from "./imagePathUtil";

const AmericanBully = () => {
  const [selectedBully, setSelectedBully] = useState(null);

  const handleCardClick = (bully) => {
    setSelectedBully(bully);
  };

  const openModal = (bully) => {
    setSelectedBully(bully);
  };

  const closeModal = () => {
    setSelectedBully(null);
  };

  const handleOutsideClick = (event) => {
    if (event.target === event.currentTarget) {
      closeModal();
    }
  };

  return (
    <PageContainer>
      <Section>
        <TextContainer>
          <Title>American Bully</Title>
          {/* <Subtitle>Origem e Linhagens do American Bully</Subtitle> */}
          <Text>
            O American Bully é conhecido por seu porte musculoso, estrutura
            robusta e cabeça larga. Eles possuem uma aparência intimidadora, mas
            têm um temperamento amigável e sociável. Essa raça é conhecida por
            ser leal, afetuosa e amigável com pessoas. Eles são conhecidos por
            sua tolerância e paciência com crianças, o que os torna uma escolha
            popular como cães de família.
          </Text>
        </TextContainer>
        <ImageContainer>
          <MainImage src={americanBullyImage1} alt="American Bully Holandês" />
        </ImageContainer>
      </Section>

      <Section>
        <ImageContainerMobile>
          <SecondaryImage
            src={americanBullyHollandImage}
            alt="American Bully"
          />
        </ImageContainerMobile>
        <TextContainer>
          <Subtitle2>Excelência na Criação Responsável</Subtitle2>
          <Text>
            Nossa abordagem à criação responsável coloca o bem-estar dos cães em
            primeiro plano. Dedicamo-nos a garantir a saúde, o cuidado adequado,
            a socialização e o desenvolvimento saudável dos filhotes. A seleção
            de cães reprodutores é meticulosa, priorizando critérios de saúde e
            temperamento. Exames genéticos e radiografias ajudam a identificar
            possíveis problemas hereditários, assegurando que os filhotes tenham
            menos predisposição a doenças genéticas.
          </Text>
        </TextContainer>
        <ImageContainerMobile2>
          <SecondaryImage
            src={americanBullyHollandImage}
            alt="American Bully"
          />
        </ImageContainerMobile2>
      </Section>

      <GridSection>
        <Subtitle2>Padrões da Raça</Subtitle2>
        <GridTitle>
          Em nosso canil, celebramos a diversidade da raça American Bully
          através de diferentes padrões, cada um com suas próprias
          características marcantes:
        </GridTitle>
        <GridContainer>
          {AmericanBullyData.bullyPatterns.map((bully) => (
            <GridItem key={bully.nome} onClick={() => openModal(bully)}>
              <GridImage src={getImagePath(bully.img_url)} alt={bully.nome} />
              <GridDescription>
                <GridTitle>{bully.nome}</GridTitle>
                <Text>{bully.tamanho2}</Text>
              </GridDescription>
            </GridItem>
          ))}
        </GridContainer>
        <TextContainer>
          <Text>
            Essa variedade nos permite atender às preferências individuais dos
            amantes da raça, mantendo o compromisso com a saúde e o temperamento
            exemplar de nossos cães. Convidamos você a conhecer o mundo
            fascinante dos American Bullys e a descobrir o padrão que mais lhe
            encanta!
          </Text>
        </TextContainer>

        {selectedBully && (
          <ModalOverlay onClick={handleOutsideClick}>
            <ModalContent>
              <CloseButton onClick={closeModal}>Fechar</CloseButton>
              <ModalTitle>{selectedBully.nome}</ModalTitle>
              <ModalSubTitle>{selectedBully.descricao}</ModalSubTitle>
              <ProductImage
                src={getImagePath(selectedBully.img_url)}
                alt={selectedBully.nome}
                aria-label={selectedBully.nome}
              />

              <ModalText>{selectedBully.tamanho}</ModalText>
              <ModalText>{selectedBully.caracteristicas}</ModalText>
              <WhatsAppButton>
                <a
                  href={`https://api.whatsapp.com/send?phone=5551992002595&text=Ol%C3%A1%20MJN%20Imports,%20estou%20interessado%20no%20${encodeURIComponent(
                    selectedBully.nome
                  )}%20${encodeURIComponent(selectedBully.tamanho)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="MJN Imports Whatsapp"
                >
                  Contato via WhatsApp
                </a>
              </WhatsAppButton>
            </ModalContent>
          </ModalOverlay>
        )}
      </GridSection>

      {""}
      {""}
    </PageContainer>
  );
};

export default AmericanBully;
