import styled from "styled-components";

import backgroundImg from "../../components/assets/background.png";

export const PuppiesContainer = styled.div`
  text-align: center;
  background-image: url(${backgroundImg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center right;
  height: auto;

  @media only screen and (max-width: 768px) {
    background-size: cover;
  }
`;

export const DivOpacity = styled.div`
  width: 60vw;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  margin: 1rem auto;
  background-color: rgba(0, 0, 0, 0.75);

  @media only screen and (max-width: 768px) {
    width: 80vw;
  }
`;

export const Title = styled.h1`
  font-size: 2rem;
  text-align: center;
  margin-bottom: 1rem;
  color: var(--light-red);
  font-family: "Archivo Black", sans-serif;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

export const Text = styled.p`
  font-size: 1.25rem;
  margin-bottom: 1rem;
  color: white;

  @media (max-width: 768px) {
    font-size: 1.25rem;
  }
`;

export const BannerImg = styled.img`
width: auto;
max-height: 90vh;

@media (max-width: 768px) {
    max-width: 90vw;
    height: auto;
    margin: 0 auto;
  }
`;