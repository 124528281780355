// BullMasterSection.js
import React from "react";
import {
  BullMasterSectionContainer,
  BullMasterTitle,
  BullMasterSubtitle,
  BullMasterParagraph,
} from "./BullMasterSectionStyles";

const BullMasterSection = () => {
  return (
    <BullMasterSectionContainer>
      <BullMasterTitle>BullMaster Premium</BullMasterTitle>
      <BullMasterSubtitle>
        Nutrição Premium para os Seus Bullys
      </BullMasterSubtitle>
      <BullMasterParagraph>
        AVR Bulls, líder em cuidado e nutrição para cães da raça Bully. Nossa
        paixão é oferecer alimentos de alta qualidade para os amados Bullys,
        proporcionando saúde, vitalidade e felicidade.
      </BullMasterParagraph>
      <BullMasterParagraph>
        Nossos clientes são os donos dedicados de cães da raça Bully, que
        valorizam seus animais de estimação como membros da família. Eles
        entendem a importância de uma nutrição excepcional para a saúde e o
        bem-estar de seus amiguinhos peludos.
      </BullMasterParagraph>
      <BullMasterParagraph>
        No universo dos produtos para cães, a raça Bully possui necessidades
        nutricionais únicas que devem ser atendidas. A categoria de ração
        premium para Bullys é uma resposta a essa necessidade, oferecendo
        ingredientes de qualidade para cães saudáveis e felizes.
      </BullMasterParagraph>
      <BullMasterParagraph>
        AVR Bulls se destaca por ser a distribuidora oficial da ração premium
        BullMaster, especialmente formulada para atender às necessidades
        nutricionais dos Bullys. Nossa expertise e compromisso com a qualidade
        nos diferenciam, fornecendo o melhor para esses cães excepcionais.
      </BullMasterParagraph>
    </BullMasterSectionContainer>
  );
};

export default BullMasterSection;
