import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    font-family: "Lato", 'Poppins', sans-serif;
  }

  body {
    margin: 0;
    padding: 0;
    height: auto;
    max-height: 100vh;
    display: flex;
    justify-content: center;
    background-color: var(--dark);
    
    @media only screen and (max-width: 600px) {
      width: 100vw;
      height: auto;
      max-height: 100vh;
    }
  }

:root {
  --dark: #0D0D0D;
  --dark-blue: #010A26;
  --brown: #3B0609;
  --dark-red: #8F1E23;
  --light-red: #FF3147;
}
  
`;

export default GlobalStyle;
